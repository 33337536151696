// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-productpage-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\productpage.tsx" /* webpackChunkName: "component---src-templates-productpage-tsx" */),
  "component---src-templates-gallerypage-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\gallerypage.tsx" /* webpackChunkName: "component---src-templates-gallerypage-tsx" */),
  "component---src-templates-product-overview-page-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\productOverviewPage.tsx" /* webpackChunkName: "component---src-templates-product-overview-page-tsx" */),
  "component---src-templates-gallery-overview-page-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\galleryOverviewPage.tsx" /* webpackChunkName: "component---src-templates-gallery-overview-page-tsx" */),
  "component---src-templates-shop-order-completed-page-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\shop\\orderCompletedPage.tsx" /* webpackChunkName: "component---src-templates-shop-order-completed-page-tsx" */),
  "component---src-templates-shop-index-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\shop\\index.tsx" /* webpackChunkName: "component---src-templates-shop-index-tsx" */),
  "component---src-templates-shop-cart-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\shop\\cart.tsx" /* webpackChunkName: "component---src-templates-shop-cart-tsx" */),
  "component---src-templates-shop-customer-details-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\shop\\customerDetails.tsx" /* webpackChunkName: "component---src-templates-shop-customer-details-tsx" */),
  "component---src-templates-shop-order-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\shop\\order.tsx" /* webpackChunkName: "component---src-templates-shop-order-tsx" */),
  "component---src-templates-faq-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\templates\\faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-pages-404-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\agent\\_work\\5\\s\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\agent\\_work\\5\\s\\.cache\\data.json")

